import React from 'react';
import MessengerCustomerChat from '../MessengerCustomerChat';
import Footer from './Footer';
import Header from '../Header';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main className="text-gray-900">{children}</main>
      <MessengerCustomerChat
        pageId="103279871781096"
        // appId="<APP_ID>"
        shouldShowDialog={true}
        htmlRef="fb-customer-chat"
      />
      <Footer />
    </>
  );
};

export default Layout;
