import { Link } from 'gatsby';
import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-10 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5">Redefining the Entertainment industry for the new generations and pionering the Interactive Entertainment.</p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <Link to="/terms">
              Terms of Service
            </Link>
          </li>
          <li>
            <Link to="/privacy">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/payment-policy">
              Payment Policy
            </Link>
          </li>

        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Others</h2>
        <ul className="mt-4 leading-loose" style={{display: 'grid', justifyContent: 'center'}}>
        <a href="//www.dmca.com/Protection/Status.aspx?ID=0c4adc54-4232-4e9c-8627-319dae1819aa" title="DMCA.com Protection Status" class="dmca-badge"> <img src ="https://images.dmca.com/Badges/DMCA_badge_grn_80w.png?ID=0c4adc54-4232-4e9c-8627-319dae1819aa"  alt="DMCA.com Protection Status" /></a>  <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
          {/* <li> */}
          {/* <div class="fb-page" data-href="https://www.facebook.com/IYGentertainment" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/IYGentertainment" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/IYGentertainment">IYG Entertainment</a></blockquote></div> */}
          {/* <a target="_blank" href="https://www.facebook.com/IYGentertainment">Facebook</a> */}

          {/* </li> */}
          {/* <li>
            <a href="https://twitter.com/HuntaroSan">Twitter</a>
          <li>
          </li>
          <li>
            <a href="https://github.com/codebushi/gatsby-starter-lander">GitHub</a>
          </li> */}
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
